@import url('fonts.css');
html {
    scroll-behavior: smooth;
}
body{
    font-family: "Roboto";
    background: #fff;
}
body.pushable .pusher{
    background-color: #fff;
}


#menu {
    height: 40px;
}
#menu .item{
    height: 42px;
    font-size:15px;
}

#svg{
    position: relative;
    left: 200px;
}

.hotele{
    width: 75%;
    margin: 0 auto;
}

.hotele h1{
    text-decoration: underline;
}

.hotele .img-big{
    width: 100%;
    max-width: 100% !important;
    float: none !important;
}

.hotele img{
    max-width: 400px;
    float: left;
}

.hotele .img-info{
    float: none;
}

.hotele .small-img img{
    width: 126px;
}

.hotele ul
{
    list-style: none;
    padding: 0px;
}

#sektor_1, #sektor_2, #sektor_3, #sektor_4{
    display: none;
}

.accepted-info{
    font-size: 26px;
    position: relative;
    left: 1%;
    top: 4px;
}


.ui.inverted.semb-pink.button{
    background: transparent;
    color: #da92a6;
    border: 3px solid #da92a6;
}

.ui.inverted.semb-pink.button:hover {
    color: #fff !important;
    background-color: #da92a6 !important;
    box-shadow: 0 0 0 0 #fff inset !important;
}

h3 {
    font-size: 1.8rem;
    font-family: Roboto-Light;
}

h3.configurator-question-header__heading {
    margin-top: 0px;
}

h4 {
    font-size: 1.8rem;
    font-family: 'RobotoCondensed-Regular';
    color: #da92a6;
}

hr {
    height: 3px;
    background-color: #da92a6;
    box-shadow: none;
    border: none;
}

.m-0 {
    margin: 0 !important;
}

.mr-3 {
    margin-right: 3em !important;
}

.mt-5 {
    margin-top: 5em !important;
}

.mt-1 {
    margin-top: 1em !important;
}

.mb-1 {
    margin-bottom: 1em !important;
}

.mb-5 {
    margin-bottom: 5em !important;
}

.p-0 {
    padding: 0 !important;
}

.w-40{
    width: 40% !important;
}

.w-60{
    width: 60% !important;
}

.border-right{
    border-right: 1px solid #da92a6;
}

.ui.menu {
    font-family: "RobotoCondensed-Regular";
    font-size: 1.3em;
    background: none;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
}

.ui.menu .item:before {
    display: none;
}

.ui.button {
    border: none;
    background: none;
    color: unset;
    font-family: RobotoCondensed-Light;
}

.ui.image.cart {
    float: left;
    margin-right: 1em;
}

.ui.input.searchbox {
    font-size: 1.3em;
    width: 447px;
    padding-left: 20px;
}

.ui.input.searchbox .ui.button.mini {
    position: absolute;
    right: 0px;
    top: 10px;
}

.numberCircle {
    float: left;
    position: relative;
    right: 28px;
    bottom: 4px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 4px 2px 2px 2px;
    background: #da92a6;
    text-align: center;
    font-size: 13px;
    color: #fff !important;
}

#sylius-cart-total {
    font-size: 1.3rem;
    line-height: 2rem;
}

#main-menu li {
    /*float: left;*/
    list-style: none;
    /*margin-right: 5.87em;*/
    font-family: RobotoCondensed-Bold;
    font-size: 22px;
}

.hide-form{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    text-align: center;
    font-family: Roboto-Bold;
    color: #da92a6;
}

.hide-form h2{
    margin-top: 30%;
}

/*#main-menu li a {*/
/*    padding-bottom: 5px;*/
/*    color: unset;*/
/*    border-bottom: 0px solid #da92a6;*/
/*    transition: border-width 0.1s linear;*/
/*}*/

/*#main-menu li a:active {*/
/*    border-bottom: 5px solid #da92a6;*/
/*}*/

#main-menu li:last-child{
    margin-right: 0px;
}

/*#main-menu li a:active {*/
/*    border-bottom: 5px solid #da92a6;*/
/*    transition: border-width 0.1s linear;*/
/*}*/


#main-menu li:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    border-bottom: solid 2px #000;
    animation: border_anim 3s linear forwards;
}

@keyframes border_anim {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

.slider-fullscreen {
    /*max-width: 1920px;*/
    width: 100vw;
    height: auto;
    object-fit: cover;
    position: relative;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
}

.slider-fullscreen .image{
    /*max-height: 600px;*/
    object-fit: cover;
    width: 100vw !important;
}

.slider-fullscreen .ui.black.button,
.carousel-latest-products-left.ui.black.button,
.carousel-latest-products-right.ui.black.button,
.carousel-promotion-products-left.ui.black.button,
.carousel-promotion-products-right.ui.black.button
{
    background: none;
    border: 1px solid #000;
    padding: 20px;
    color: #000;
    border-radius: 0;
}


.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-div
{
    height: 100%;
}

.slider-fullscreen .carousel-item{
    width: 100vw !important;
}

.carousel-item{
    height: 100%;
    position:relative;
}

.taxons .ui.fluid.card{
    height: 100%;
    min-height: 530px;
}
#lozka-kontynentalne .fluid.card {
    padding: 2em!important;
}
.additional-description table, td, th {
    border: 1px solid;
    padding: 0.5em
}
.additional-description table {
    border-collapse: collapse;
}

/*.box-bottom-info{*/
/*    position: absolute;*/
/*    bottom: 25px;*/
/*    width: 86%;*/
/*}*/

.taxons .prices{
    display: flex;
    margin: auto;
    padding-right: 1em;
    position: absolute;
    right: 0em;
    bottom: 0.5em;
}
.homepage .prices{
    display: flex;
    margin: auto;
    padding-right: 1em;
    right: 0em;
    bottom: 0.5em;
}
.taxons .prices,
.homepage .photo_and_price .prices{
    display: none;
}
.homepage .prices,
.taxons .photo_and_price .prices{
    display: block;
}
.filter_menu .inside_filter .ui.header {
    display: block;
}
.sylius-product-original-price, .sylius-product-price
{
    float: left;
    text-align: center;
    font-size: 24px;
}

.sylius-product-original-price{
    color: #da92a6;
}

.sylius-product-price
{
    margin-left: 20px;
    color: #000000;
    font-weight: bold
}

.recommended-product-price{
    float: left;
    text-align: left;
}

.recommended-product-price .sylius-product-price{
    margin: 0;
    clear: both;
}

.carousel-left-top, .carousel-right-top{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-left-top
{
    left: 50px;
}

.carousel-right-top
{
    right: 50px;
}

.carousel-latest-products-left, .carousel-latest-products-right, .carousel-promotion-products-left, .carousel-promotion-products-right{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-latest-products-left, .carousel-promotion-products-left
{
    left: -100px;
}

.carousel-latest-products-right, .carousel-promotion-products-right
{
    right: -100px;
}

.carousel-latest-products .slick-dots, .carousel-promotion .slick-dots
{
    bottom: -50px;
    right: unset;
    margin: 0 auto;
    float: unset;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.arrow-icon {
    font-size: 25px;
    font-family: Icons;
}

.slick-dots {
    position: relative;
    bottom: 83px;
    right: 242px;
    float: right;
}

.slick-dots li {
    list-style: none;
    float: left;
    margin: 10px;
}

.slick-dots li button {
    background: none;
    border: 1px solid #da92a6;
    border-radius: 0;
    color: rgba(255,255,255,0);
    cursor: pointer;
}

.slick-dots .slick-active button {
    background: #da92a6;
}

.carousel-latest-products .slick-slide,
.carousel-promotion .slick-slide
{
    width: 484px;
    padding: 26px;
}

.slick-slide .ui.card, .slick-slide .ui.cards>.card {
    border-radius: unset;
    border: 1px solid #000;
    padding: 0px;
}

.slick-slide .ui.card>.image{
    background: #fff;
}

.slick-slide .ui.card>.content, .slick-slide .ui.cards>.card>.content
{
    border-radius: unset;
    border: none;
}

.slick-slide .ui.card>.content>.header
{
    font-size: 2.3em;
    font-family: RobotoCondensed-Bold;
    line-height: 2em;
}

.slick-slide .ui.card>.content>.header:hover
{
    color: #666;
}

.buy-button{
    width: 100%;
    height: 66px;
    padding: 13px 20px;
    background-color: #22262f;
    font-size: 16px;
    font-family: Roboto-Bold;
    line-height: 41px;
    letter-spacing: 0.8px;
    text-align: center;
    color: #f0f1f5;
    cursor: pointer;
    outline: none;
    margin-left: auto;
    align-items: center;
    transition: 0.3s;
    display: block;
    display: flex;
    justify-content: center;
    align-content: center
}
.homepage .buy-button{
    height: 55px;
    padding: 0px;

}
.buy-button:hover{
    color: #da92a6;
}
.homepage .buy-button:hover{
    color: #22262f;
    background-color: #f0f1f5;
    border: 1px solid #22262f;

}

.carousel-item .slider-text{
    color: #666666;
    width: auto;
    max-width: 30%;
    padding: 2em;
    position:absolute;
    top: 10%;
    left: 60%;
}

.slider-text p{
    font-size: 0.9vw;
}

.slider-text h1{
    font-size: 1.5vw;
}

.slider-text h3{
    font-family: RobotoCondensed-Bold;
    font-size: 3em;
    color: black;
}

.slider-top-button{
    font-size: 1rem;
    color: #000 !important;
    background: none;
    border: 1px solid #000;
    padding: 20px 65px;
    float: left
}


/*.slider-top-button:after {*/
/*    color: black;*/
/*    border-right: 2px solid currentcolor;*/
/*    border-bottom: 2px solid currentcolor;*/
/*    content: '';*/
/*    position: absolute;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    right: 190px;*/
/*    transform: rotate(-45deg)*/
/*}*/






.search__title_box{
    background-color: #da92a6;
    padding: 25px;
}

.search {
    background-color: #fff;
    color: #000;
}

#search-products{
    border: 3px solid #da92a6;
}

.search__title, .huge.header {
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #000000 !important;
}

/*h1:before,*/
/*h1:after, h2:before, h2:after {*/
/*    background-color: #000;*/
/*    content: "";*/
/*    display: inline-block;*/
/*    height: 1px;*/
/*    position: relative;*/
/*    vertical-align: middle;*/
/*    width: 10%;*/
/*}*/

/*h1:before, h2:before {*/
/*    right: 0.5em;*/
/*    margin-left: -50%;*/
/*}*/

/*h1:after, h2:after {*/
/*    left: 0.5em;*/
/*    margin-right: -50%;*/
/*}*/


h2.search__title {
    background: #da92a6;
    padding: 0 10px;
}

.search__selects-section {
    display: flex;
    align-items: flex-end;
}

.search__selects-section > div {
    position: relative;
}

search__hardness-wrapper {
    z-index: 101;
}

.ui.selection.dropdown .menu > .item {
    font-size: 11px;
    border-top: 1px solid #cecece;
}

.ui.selection.dropdown .menu > .item.select-bold {
    font-weight: bold;
}

.search__label {
    font-size: 13px;
    font-family: Roboto-Bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 1.3px;
    text-align: left;
    color: #da92a6;
    display: block; }

.search__select {
    width: 345px;
    height: 55px;
    margin: 0;
    padding: 0 0 0 20px;
    background-color: rgba(255, 255, 255, 0);
    display: block;
    font-size: 18px;
    font-family: Roboto-Light;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    cursor: pointer;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #000;
    z-index: 10;
}

.search__button {
    width: 240px;
    height: 55px;
    background-color: #22262f;
    font-size: 16px;
    font-family: Roboto-Bold;
    line-height: 41px;
    letter-spacing: 0.8px;
    text-align: center;
    color: #f0f1f5;
    cursor: pointer;
    outline: none;
    margin: 0 auto !important;
    align-items: center !important;
    transition: 0.3s;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    display: flex !important;
    border-radius: 0px !important;
}

.search__button:hover {
    color: #da92a6;
    background: #000;
    outline: none !important;
    text-decoration: none !important;
    animation-name: changecolor;
}

.search__button > img {
    height: 14px !important;
    width: 14px !important;
    margin-right: 15px; }

.products-filter .search__button > img {
    height: 57% !important;
    width: 57% !important;
    margin-right: 0;
}

.products-filter #products-search-button{
    width: 59px;
    height: 46px;
    background-color: #da92a6;
    font-size: 16px;
    font-family: Roboto-Bold;
    line-height: 41px;
    letter-spacing: 0.8px;
    text-align: center;
    color: #f0f1f5;
    cursor: pointer;
    outline: none;
    margin: 0 auto !important;
    align-items: center !important;
    transition: 0.3s;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    display: flex !important;
    border-radius: 0px !important;
    position: relative;
    top: -6px;
}

.products-filter .ui.inline.dropdown>.text{
    font-weight: 100;
    font-size: 12px;
}

.search__select-chevron-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    /*background: #da92a6;*/
    background: #246C58;
    padding: 1.7em;
    z-index: 1;
    height: 100%;
    border-left: 1px solid #fff;
    filter: invert();
}

.search__select-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #000000; }

.search__selects-section {
    padding: 40px;
}

#sylius-product-selecting-variant
{
    border: none;
    background: none;
    margin-bottom: 0;
}
.ui.large.flowing.cart.popup.bottom.left.transition.visible,
.ui.large.flowing.cart.popup.bottom.left.transition.transition{
    z-index: 100000;
}
.ui.primary.button, .ui.primary.buttons .button,
.ui.basic.button, .ui.basic.buttons .button{
    background-color: #da92a6!important;
    color: #fff!important;
    text-shadow: none;
    background-image: none;
    border: 1px solid #da92a6!important;
    box-shadow: none!important;
}
.ui.primary.button:hover, .ui.primary.buttons .button:hover,
.ui.basic.button:hover, .ui.basic.buttons .button:hover{
    background-color: #fff!important;
    color: #da92a6!important;;
    text-shadow: none;
    background-image: none;
}


.search__select-item {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #000000; }


/* PRODUCT */
#sylius-product-adding-to-cart label
{
    padding: 25px 0px 0px 0px;
}

#sylius-product-adding-to-cart .ui.checkbox{
    text-align: center;
}

#sylius-product-adding-to-cart .fields
{
    display: inline-flex !important;
    padding: 15px;
}

#sylius-product-adding-to-cart label:before
{
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 0;
    left: 35%;
    background-color: #babbbc;
}

#sylius-product-adding-to-cart .ui.toggle.checkbox input:checked~label:before{
    background-color: #da92a6 !important;
}

#sylius-product-adding-to-cart label:after
{
    display: none;
}

#sylius-product-adding-to-cart .ui.checkbox input[type=checkbox],
#sylius-product-adding-to-cart .ui.checkbox input[type=radio]
{

}

/*PRODUCT*/
#sylius-product-name
{
    border: none;
}

#product-price.ui.huge.header, #promo-product-price.ui.huge.header {
    float: right;
    text-align: right;
    font-family: 'Roboto-Bold';
    font-size: 3em;
}

#promo-product-price {
    color: #da92a6 !important;
}

.recommend-price-title {
    text-align: right;
    font-family: RobotoCondensed-Light;
    float: right;
    width: auto;
    color: #000;
    line-height: 14px;
}

#sylius-product-adding-to-cart .ui.toggle.checkbox input{
    display: none;
    opacity: 0 !important;
    z-index: 0;
}

.retailer-info{
    border: 1px solid #da92a6;
    padding: 1rem;
    margin-bottom: 1em;
}

.retailer-address{
    font-size: 0.9rem;
}

.retailer-content p
{
    margin: 0;
}

.retailer-buy-button {
    background: #000;
    color: #fff;
    width: 94%;
    height: 50px;
    border: none;
    font-size: 1.9rem;
    font-family: Roboto-Bold;
}

.retailer-login-container {
    margin: 50px 0;
    text-align: center;
}

.retailer-content {
    margin: 3em;
}

.retailer-content h1 {
    font-size: 40px;
    font-family: Roboto-Bold;
}


.retailer-login-container .ui.header > .icon + .content,
.retailer-login-container .ui.header > .icon,
.retailer-login-container .ui.padded.segment {
    display: inline-block;
}

.province-select {
    width: 100%;
    height: 50px;
    font-size: 1.7rem;
    font-family: 'RobotoCondensed-Light';
    color: #da92a6;
    padding: 0px 0.6em;
    background-color: #fff;
    font-weight: 900;
    border: 1px solid #da92a6;
}
.working-hours{
    font-size: 10px;
    line-height: 11px;
}

.ui.huge.button, .ui.huge.buttons .button, .ui.huge.buttons .or{
    border-radius: 0;
}
.add-to-basket-retailer-column{
    padding: 0px 0 0 15% !important
}

.add-to-basket-retailer-column .ui.labeled.icon.button, .add-to-basket-retailer-column .ui.labeled.icon.buttons .button{
    padding-left: 0em !important;
    padding-right: 0em !important;
    width: 94%;
    height: 50px;
    background-color: #fff;
    color: #da92a6;
    text-transform: none !important;
    font-size: 1.3em !important;
}

.ui.tabular.menu .active.item{
    background-color: #da92a6;
    border: none;
    color: #fff;
    border-radius: unset !important;
}

.ui.tabular.menu .item{
    width: 20%;
    /*margin-right: 14%;*/
    font-size: 1.6rem;
    justify-content: center;
    padding: 0.7em 0;
}

.cart-header-icon{

}
.three-squares{
    position: absolute;
    top: 73px;
    left: 264px;
    width: 70px;
    height: 20px;
    background-color: #000;
    background-size: 25px 25px;
    background-image: conic-gradient(
        #fff 200deg,
        #c2c3c1 90deg 180deg,
        #fff 180deg 270deg,
        #c2c3c1 270deg
    );
}

#sylius-cart-items.table,
#sylius-cart-items tr,
#sylius-cart-items td,
#sylius-cart-items thead,
#sylius-cart-items tr,
#sylius-cart-items th{
    border-collapse:collapse;
    border: none !important;
}

.ui.header>.image:not(.icon), .ui.header>img{
    width: unset;
}

.ui.segment{
    border: none;
    box-shadow: none;
}

.sylius-product-name{
    color: #000;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-family: Roboto-Bold;
}

#sylius-cart-items img{
    margin-right: 7rem;
}

.sylius-unit-price{
    font-family: Roboto-Bold;
    color: #000;
    font-size: 2rem;
}

.sylius-quantity.ui.form input{
    width: 65px;
    border-radius: 0px;
    padding: 10px 10px;
    font-family: Roboto-Bold;
    font-size: 2rem;
}

.remove.icon{
    color: #fff;
    background: #da92a6;
    padding: 7px;
    display: inline;
}

.sylius-total{
    font-family: RobotoCondensed-Bold;
    color: #da92a6;
    font-size: 2.7rem;
}

#sylius-cart-items .ui.button:hover{
    background-color: unset;
}

#sylius-cart-items .ui.button:hover .remove.icon{
    background: red;
}

#sylius-cart-update{
    border: 2px solid #000000;
    border-radius: 0;
    background: none;
    /*float:right;*/
    margin-right:25px;
    transition: 0.2s;
}
#sylius-cart-update:hover{
    border: 2px solid #da92a6;
    background: #da92a6;
    transition: 0.2s;
    color: #fff;
}

button{
    font-family: RobotoCondensed-Bold !important;
    font-size: 1.5rem !important;
    text-transform: uppercase !important;
    border-radius: 0px !important;
}

#sylius-cart-clear{
    border: 2px solid #b6b6b6;
    color: #b6b6b6
}
#sylius-cart-clear:hover {
    border: 2px solid #da92a6;
    background: #da92a6;
    transition: 0.2s;
    color: #fff;
}

table#sylius-checkout-subtotal td{
    border: none;
}

#sylius_cart {
    display: flex;
    justify-content: end;
    height: unset;
}

#checkout-button {
    border-radius: 0px;
    padding: 0.5em 2.5em !important;
    font-size: 1.5rem !important;
    float: right;
    background-color: #da92a6 !important;
    color: #fff !important;
}

#refresh_cart {
    height: 47px;
    position: relative;
    top: 77px;
}

#checkout-button,
#refresh_cart {
    border: 2px solid #da92a6;
    background: none;
    color: #da92a6;
    font-family: RobotoCondensed-Bold;
    font-size: 1.7rem;
    text-transform: uppercase;
    padding: 15px;
    transition: 0.2s;
}
#refresh_cart {
    font-size: 1.2rem;
    margin-top: 0em;
}
#refresh_cart:hover {
    cursor: pointer;
}
#checkout-button:hover,
#refresh_cart:hover {
    background: #da92a6;
    color: #fff;
    transition: 0.2s;
}

#sylius-cart-grand-total {
    font-size: 3rem;
    font-family: RobotoCondensed-Bold;
    color: #da92a6;
    display: flex;
    align-items: center;
}

.register-choice-box{
    background-color: #ececec;
    width: 460px !important;
    margin-right: 30px !important;
    height: 320px;
    padding: 30px !important;
    text-align: left !important;
}

.register-choice-box:last-of-type{
    margin-right:0px !important;
}

#sylius-api-login input{
    height: 50px;
    border-radius: unset;
    width: 100%;
    margin-bottom: 1em;
}

#sylius-api-login-submit{
    height: 50px;
}

#sylius-api-login .search__button.button{
    margin-top: 36px !important;
}

#clear_cart_select_shipping{
    position: relative;
    top: -47px;
    left: 311px;
    width:300px;
}

#sylius-shipping-methods .content{
    flex: unset;
    width: 50%;
}

#sylius-shipping-methods .field{
    width: 25%;
}

#sylius-shipping-methods .ui.label,
#sylius-payment-methods  .ui.label{
    border: none;
    color: unset;
    background-color: unset;
    font-size: 2em;
    color: #000;
    padding: 0;
    margin: 0;
}

#sylius-shipping-methods .ui.unmargined.segments,
#sylius-payment-methods .ui.unmargined.segments{
    box-shadow: none;
}

#sylius-shipping-methods .ui.segment,
#sylius-payment-methods .ui.segment{
    border: none !important;
    box-shadow: none !important;
}

#sylius-shipping-methods .ui.dividing.header,
#sylius-payment-methods  .ui.dividing.header{
    border: none !important;
    margin-bottom: 50px;
}

#sylius-shipping-methods .ui.items>.item{
    margin-top:3em;
}

.payment-method .row{
    text-align: center;
    padding: 20px;
}

.payment-method .payment-method-label{
    color: #000;
    font-size: 1.5em;
}

.payment-method .row .ui.radio.checkbox{
    margin-left: 12px;
}

#sylius-order .sylius-product-name{
    font-size: 2rem;
}
#sylius-order .sylius-product-options{
    font-size: 1rem;
}

#sylius-order.ui.celled.table{
    color: unset !important;
    border-collapse: collapse !important;
    border: none;
}

#sylius-order.ui.celled.table th,
#sylius-order.ui.celled.table td {
    background: none;
    border-left: none;
    border-right: none;
}

.ui.radio.checkbox .box:after, .ui.radio.checkbox label:after{
    transform: unset !important;
    width: 15px;
    height: 15px;
}

.ui.radio.checkbox .box:before, .ui.radio.checkbox label:before{
    background-color: #ececec;
}

.ui.radio.checkbox input:checked ~ .box:after, .ui.radio.checkbox input:checked ~ label:after {
    background-color: #DC98AB !important;
}

.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 1.8857em;
}

h1.page-title {
    margin: 0 15px;
}

.ui.items > .item > .content > .header:not(.ui) {
    font-size: 2em;
}

.ui.items > .item .extra {
    width: auto;
}

.register-button {
    text-align: center;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 120px);
}

.register-button a{
    text-transform: uppercase;
    font-size: 1.3em;
}

.register-text{
    font-family: Roboto;
    font-size: 1.8rem;
}

.ui.loadable.form{
    height: 94%;
}

#sylius_shop_checkout_address{
    display: none;
}

.inactive{
    pointer-events: none;
    opacity: 0.2;
    background: #CCC;
}

.next.button{
    padding-left: 80px;
    padding-right: 80px;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 120px);
}

.taxons .ui.card > .content, .ui.cards > .card > .content {
    border-top: none;
    border-left: 1px solid #d4d4d5;
    border-right: 1px solid #d4d4d5;
    border-bottom: 1px solid #d4d4d5 !important;
    min-height: 200px;
}

.taxons.other.clients .ui.card > .content, .ui.cards > .card > .content {
    min-height: auto;
}

.taxons .inner .content{
    padding-top: 2em;
}
.taxons .ui.card, .ui.cards > .card{
    border-radius: 0;
    box-shadow: unset;
}
.taxons .blurring.dimmable.image {
    border-left: 1px solid #d4d4d5;
    border-right: 1px solid #d4d4d5;
    border-radius: .28571429rem .28571429rem 0 0 !important
}

.taxons .box-bottom-info{
    padding-top: 0.2em;
}
.taxons .ui.card > :first-child, .taxons .ui.cards > .card > :first-child{
    border-top: 1px solid #d4d4d5!important;
}
.taxons .ui.image{
    border-radius: .28571429rem .28571429rem 0 0 !important
}

.taxons .buy-button {
    background-color: #da92a6;
    padding: 0;
    height: auto;
    border: 2px solid #da92a6;
    font-size: 1.5em
}

.taxons .buy-button:hover {
    background-color: #ffffff;
    color: #da92a6;
}

.taxons .ui.three.cards > .card {
    padding: 0.5em 1em;
}

.taxons .ui.inline.dropdown.sylius-paginate,
.taxons .ui.inline.dropdown.sort{
    align-items: center;
}
.taxons .filters {
    padding: 0.5em 0 0.5em 0.5em;
    border: 1px solid #a7b2b1;
    text-align: left;
    color: #a7b2b1;
}

.taxons .filters_arrow {
    background: #a7b2b1;
    height: 100%;
    padding: .47em;
    border: 1px solid #a7b2b1;
    color: #fff;
    float: right;
    padding: 10px 12px 22px;
    position: relative;
    top: -43px;
    z-index: 1;
}
.taxons .filters_arrow .icon{
    margin-right: 0!important;
}

.taxons .sylius-paginate .chevron.icon,
.taxons .sort .chevron.icon{
    color: #ffffff;
}
.taxons .sylius-paginate .menu.transition.visible,
.taxons .sylius-paginate .menu.transition.animating {
    width: calc(100% - 3.5em);
    margin-left: 3.5em;
}
.taxons .sylius-paginate .menu.transition.visible a,
.taxons .sylius-paginate .menu.transition.animating a {
    color: #a7b2b1;
}
.taxons .sylius-paginate .above {
    position: absolute;
    top: -2em;
    left: 4em;
    font-weight: lighter;
    font-size: small;
    color: #a7b2b1;
}

.above {
    position: absolute;
    top: -9px;
    left: 67px;
    font-weight: lighter;
    font-size: small;
    color: #a7b2b1;
}

.taxons .sort_ico {
    margin-right: 0.7em;
}
.taxons .menu.transition.visible ,
.taxons .menu.transition.animating {
    width: calc(100% - 3.5em);
    margin-top: 1em;
}
.taxons .menu.transition.visible a,
.taxons .menu.transition.animating a {
    color: #a7b2b1!important;
}

.taxons .filter_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}
.taxons .inside_filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.taxons .ui.grid.taxon_margin_top {
    margin-top: 2em;
}
.taxons .sort_ico,
.taxons .sort_ico img {
    height: 4em;
    width: 4em;
}

.taxons .filter_menu .ui.header:last-child,
.taxons .filter_menu .ui.header{
    margin-bottom: 4em;
    margin-top: 2em
}
.taxons .photo_and_price .ui.image {
    height: 305px;
    object-fit: cover;
}
.taxons .province-select-wrapper {
    margin-top: -1em;
}

.taxons.suggest {
    margin-bottom: 2em;
}

.taxons.suggest .ui.image {
    height: auto;
    min-height: unset;
}

.filter_menu .search__selects-section {
    padding: 0;
}

.filter_menu .ui.selection.dropdown {
    min-width: 100%;
    min-height: unset;
    padding: 0;
    border: none;
    line-height: 1.5;
    z-index: 2;
    background: none;
    box-shadow: none;
}

.filter_menu .ui.selection.dropdown:hover, .filter_menu .ui.selection.dropdown:active {
    box-shadow: none;
    border: none;
}

.filter_menu .ui.selection.dropdown.visible {
    box-shadow: none;
    border: unset;
}

.filter_menu .ui.upward.active.selection.dropdown:hover {
    box-shadow: none;
}


.filter_menu .filters .dropdown .icon {
    display: none;
}

.filter_menu .filters {
    height: 35px;
    width: 170px;
    margin-top: 10px;
}

.filter_menu .filters.product {
    width: 245px;
}

.filter_menu .filters{
    z-index: 11;
}

.photo_and_price {
    position: relative;
}

.photo_and_price.new_label:before,
.photo_and_price.promo_label:after,
.photo_and_price.percent_label > span:before {
    position: absolute;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
    font-weight: bold;
}
.photo_and_price.new_label:before {
    content: "Nowość";
    right: 0;
    padding: 0.7em 0em;
    background-color: #da92a6;
    color: white;
    font-size: 1.2em;
    width: 140px;
}

.photo_and_price.promo_label:after {
    content: "PROMOCJA";
    right: 0;
    padding: 0.7em 0em;
    background-color: #98bbc7;
    color: white;
    font-size: 1.1em;
    width: 140px;
}

.photo_and_price.promo_label.new_label:after{
    top: 3em;
}

.photo_and_price.percent_label > span:before {
    content: "%";
    left: 0;
    padding: 0.42em;
    background-color: #da92a6;
    color: white;
    font-size: 2em;
    height: 40px;
}

.homepage .photo_and_price.percent_label > span:before {
    right: 0;
    left: unset;
    width: 70px;
}

.homepage .photo_and_price.percent_label.promo_label.new_label > span:before {
    top: 3.3em
}

.homepage .photo_and_price.percent_label.promo_label > span:before{
    top: 1.6em
}
.homepage .photo_and_price.percent_label.new_label > span:before{
    top: 1.7em
}

.homepage .ui.card > :first-child, .ui.cards > .card > :first-child {
    border-radius: 0!important;
}
.homepage .box-bottom-info,
.homepage .content{
    padding: 2em!important;
}
.homepage .sylius-product-price{
    color: #22262f;
}

.fullscreen{
    max-width: 100vw !important;
    width: 100vw !important;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}
.fullscreen .pic{
    position: absolute;
}
.fullscreen .pic{
    width: 100vw;
    height: 100%;
    object-fit: cover;

}
.fullscreen .center_container{
    position: absolute!important;
    top: 1em;
    margin-left: 50% !important;
    margin-right: 50% !important;
    transform: translateX(-50%);
}

.fullscreen{
    overflow: hidden;
    position: relative;
}

.title_pic {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2em;
    margin: 0px;
}
.pic_text{
    padding: 5em 0em 5em 2em !important;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 1.4em;
    line-height: 1.8em;
}

.pink{
    color: #da92a6;
}
.white{
    color: #ffffff !important;
}
.white a{
    color: #ffffff!important;
}
.fullscreen.mt {
    margin-top: 8em;
}

.slick-dots li button{
    height: 20px;
    width: 20px;
}

.fullscreen.mb{
    margin-bottom: -4em;
}
.searchbox {
    display: flex;
    align-items: center;
}
.ui.input.searchbox .ui.button.mini{
    top: 52%;
    padding: 0;
    transform: translateY(-50%);
}

.ui.input.searchbox .ui.button:hover {
    background-color: transparent;
}

.slider-top-button {
    display: flex;
    align-items: center;
}
.ico_check {
    width: 25px;
    margin-left: 20px
}
.ico_check img {
    width: 100%;
}
#main-menu {
    padding-left: 0;
    margin: 0 20px;
}
#main-menu ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#main-menu ul li {
    padding: 0;
    z-index: 101;
}

.item a:after {
    content: "";
    position: absolute;
    left: 0;
    top: 80%;
    width: 100%;
    border-bottom: 0px solid black;
}

#main-menu li a {
    padding-bottom: 5px;
    color: unset;

}

#main-menu .item a:hover:after {
    border-bottom: 5px solid #da92a6;
    transition: border-width 0.1s linear;
}
#main-menu .active2.item a:after {
    border-bottom: 5px solid #da92a6;
    transition: border-width 0.1s linear;
}

#main-menu .active.item a:after {
    border-bottom: 5px solid #da92a6;
    transition: border-width 0.1s linear;
}

.ui.menu .active.item{
    background: none;
}

/*#main-menu .item a:active {*/
/*    border-bottom: 5px solid #da92a6;*/
/*    transition: border-height 0.1s linear;*/
/*}*/
.menu_button{
    display: none;
}
#main-menu .searchbox-form{
    display: none;
}
#sylius-cart-button{
    display: flex;
}
#log_reg_bas {
    width: auto;
}
.ui.three.column.stackable.grid{
    justify-content: space-between;
}
#log_reg_bas .ui.two.column.grid,
#log_reg_bas .ui.one.column.grid{
    justify-content: end;
}
#log_reg_bas .ui.two.column.grid .column,
#log_reg_bas .ui.one.column.grid .column{
    width: auto;
}
.slick-dots {
    display: flex;
    position: absolute;
    width: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    bottom: 10px;
}
.carousel-item .slider-text {
    padding: 1em 2em;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.filter_wrap{
    display: flex;
    position: relative;
    margin-top: 1em;
    margin-right: 22px;
}
.sub_menu {
    position: absolute;
    top: calc(100% + 0em);
    padding: 0;
    flex-direction: column;
    z-index: 1000;
    /*width: 1452px !important;*/
    background-color: #f5f5f5;
    left: calc(100vw + 240px);
    opacity: 0;
    width: 0;
}

.sub_menu .ui.stackable.menu {
    width: 240px !important;
    background-color: #da92a6;
    padding: 28px 0px 50px;
    justify-content: start !important;
    height: 100%;
}
.sub_menu .ui.stackable.menu {
    flex-direction: column;
    width: auto;
}
.sub_menu li a {
    text-transform: uppercase;
    font-weight: normal;
    font-family: Roboto;
    color: #fff !important;
    padding: 10px 25px;
}
.sub_menu ul li a {
    font-size: 16px !important;
}
/*.sub_menu li:hover a {*/
/*    font-family: Roboto-Bold;*/

/*}*/
.sub_menu.show{
    left: 0;
    opacity: 1;
}
.sub_sub_sub_menu.show{
    opacity: 1;
    margin-left: 240px;
}
.sub_menu.show_sub{
    width: 700px !important;
    -webkit-transition: width 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out;
    transition: width 0.2s ease-in-out;
}
.sub_menu.show_sub.show_sub_sub{
    width: 1452px !important;
    -webkit-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    -o-transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
}
/*.sub_menu {*/
/*    display: none;*/
/*}*/
/*.item:hover .sub_menu{*/
/*    display: block;*/
/*}*/
/*.sub_menu li:hover > .sub_sub_menu{*/
/*    opacity: 1;*/
/*    left: 240px;*/
/*}*/
.sub_sub_menu.show > ul > .item,
.sub_sub_sub_menu.show > ul > .item {
    opacity: 0;
}
.sub_sub_menu.show{
    opacity: 1;
    left: 240px;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.sub_sub_menu {
    position: absolute;
    padding: 0;
    z-index: 1000;
    width: 250px;
    left: calc(100vw + 240px);
    top: 0;
    opacity: 0
}
.sub_sub_menu .ui.stackable.menu .item .sub_sub_menu_img {
    display: none;
}
/*.sub_sub_menu li a {*/
/*    padding-left: 250px;*/
/*}*/
.sub_sub_menu .ui.stackable.menu .item:hover .sub_sub_menu_img {
    display: block;
    position: absolute;
    left: 250px;
    width: 140px;
    height: 140px;
    object-fit: cover;
    top: 28px;
}
.sub_menu a:after,
.sub_menu a:before{
    content: unset !important;
}
.sub_menu li{
    position: unset!important;
}
.sub_sub_menu li a{
    color: #000000!important;
    /*font-family: Roboto!important;*/
}
.sub_sub_menu li:hover a{
    /*font-family: Roboto-Bold!important;*/
}
.sub_sub_menu .ui.stackable.menu{
    background-color: transparent;
}
.sub_menu .item:hover {
    position: unset !important;
}
.sub_sub_menu .ui.stackable.menu {
    width: 350px !important;
    margin-left: 10px;
}
/*.sub_sub_menu{*/
/*    display: none;*/
/*}*/
.sub_sub_sub_menu {
    position: absolute;
    margin-left: calc(100vw + 240px);
    opacity: 0;
    width: 500px !important;
    padding-left: 160px;
    top: 0;
}

.sub_sub_sub_menu .ui.stackable.menu {
    width: 660px !important;
    margin-left: 0;
}

/*.sub_sub_menu li.item:hover .sub_sub_sub_menu{*/
/*    opacity: 1;*/
/*    margin-left: 240px;*/
/*}*/
.sub_sub_sub_menu li a{
    padding-left: 0;
}

.sub_sub_menu li .sub_sub_sub_menu .item a {
    text-transform: none;
    font-family: Roboto !important;
    font-weight: normal;
    padding: 5px 10px;
}
.sub_menu li .sub_sub_menu li a{
    padding: 7px 10px;
}
.sub_sub_menu li .sub_sub_sub_menu .item:hover a {
    font-family: Roboto-Bold !important;
}
.sub_sub_menu li .sub_sub_sub_menu .item .sub_sub_sub_menu_img {
    position: absolute;
    left: 530px;
    top: 20px;
    height: 250px;
    width: 330px !important;
    object-fit: contain;
    display: none;
}
.sub_sub_menu li .sub_sub_sub_menu .item:hover .sub_sub_sub_menu_img {
    display: block;
}
#footer{
    margin-top: 0;
}
.filter_button{
    display: none;
}
.ui.pagination.menu {
    justify-content: center;
    width: 100%;
    margin: 1em 0;
}
#bitbag-pages.blog {
    margin: 0;
}
.blog .item.bitbag-page {
    padding: 0;
}
.blog .content.ui.stackable.two.column.grid {
    margin: 0;
}

.blog_img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.column.blog_content {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

}
.item.bitbag-page,
.item.bitbag-page .column{
    height: 350px
}

.item.bitbag-page:nth-child(even) .content{
    flex-direction: row-reverse;
}
.item.bitbag-page .image_blog{
    padding: 0!important;
}
.item.bitbag-page:nth-child(odd){
    background-color: #ffffff;
}
.item.bitbag-page:nth-child(even){
    background-color: #f5f5f5;
}
.item.bitbag-page:nth-child(odd) .image_blog:after {
    content: "";
    position:absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(270deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    width: 30%;
}
.item.bitbag-page:nth-child(odd) .blog_content:after {
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    width: 10%;
}
.item.bitbag-page:nth-child(even) .image_blog:after {
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(245,245,245);
    background: linear-gradient(90deg, rgba(245,245,245,1) 0%,rgba(245,245,245,1) 50%, rgba(245,245,245,0) 100%);
    width: 30%;
}
.item.bitbag-page:nth-child(even) .blog_content:after {
    content: "";
    position:absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: rgb(245,245,245);
    background: linear-gradient(270deg, rgba(245,245,245,1) 0%,rgba(245,245,245,1) 50%, rgba(245,245,245,0) 100%);
    width: 10%;
}

.item.bitbag-page .blog_content.column{
    padding-right: 7em;
    padding-left: 7em;
}

.blog .item.bitbag-page{
    margin-top: 1em;
}
.read_more {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3em;
    padding: 0.7em 1em;
    border: 1px solid black;
    transition: all 0.3s;
    font-family: Roboto-Bold;
}
.read_more:hover{
    color: white;
    background-color: #da92a6;;
    border-color: #da92a6;;
}
.wrap_read_more{
    margin-top: 1em;
}
.blog h3,
.blog_top h3{
    text-transform: uppercase;
    font-weight: 900;
    font-family: roboto-bold;
    font-size: 2.5em;
}
.blog_content p,
.blog_top p{
    text-transform: uppercase;
    font-weight: 300;
    font-family: roboto-light;
}

.ui.one.column.blog_top {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
}
.blog_top_image {
    padding-left: 20%;
}
.blog_top_content {
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    padding-left: 2em;
}
.blog_top .wrap_read_more{
    margin-top: 4em;
}
.blog_top .read_more{
    padding: 1em 2em;
}
.blog_top h3{
    margin-bottom: 2em;
}
/*.ui.header {*/
/*    display: none;*/
/*}*/
.blog_content_show h1::after,
.blog_content_show h1::before{
    content: unset;
    display: block;
}
.blog_content_show h1,
.blog_content_show h2,
.blog_content_show h3,
.bold_roboto{
    font-family: roboto-bold;
    text-transform: uppercase;
}

.blog_content_show h1.blog_header{
    font-size: 2.5em;
}

.page-categories{
    margin-top: 0.5em;
    margin-bottom: 0em;
    text-align: justify;
}

.page-category{
    /*color: #666;*/
    text-transform: uppercase;
    /*font-size: 20px;*/
    font-family: 'RobotoCondensed-Bold';
}

/*.page-category:hover{*/
/*    color: #da92a6;*/
/*}*/
.ui.blue.labeled.icon.button {
    background-color: #da92a6;
}


.ui.labeled.icon.button .icon.list {
    background-color: #da92a6;
    color: #fff;
    box-shadow: -1px 0 0 0 transparent inset;
}
.ui.labeled.icon.button{
    background-color:  #da92a6f2;
    color: #fff;

}
.ui.icon.info.message {
    background-color: #da92a6d6;
    border-color: #da92a6 ;
    box-shadow: 0 0 0 1px #da92a6 inset,0 0 0 0 transparent;
    color: #fff;
}
.ui.icon.info.message .header {
    color: #fff;
}
.retailer-address p {
    text-transform: capitalize !important;
    word-break: break-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.icons_product{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.other_clients .ui.fluid.card.to_style_height {
    min-height: 200px;
}
.other_clients .blurring.dimmable.image {
    border: none !important;
}
.other_clients .buy-button {
    width: 60%;
    display: block;
    padding: 0px !important;
    text-align: center;
    margin: 0;
    color: #da92a6;
    background-color: transparent;
    border: 1px solid #da92a6;
    font-size: 1em;
}
.ui.three.doubling.cards.taxons.other_clients {
    padding-bottom: 2em;
}
.other_clients h5 {
    font-family: roboto-bold;
    font-size: 1.5em;
}
.other_clients .content {
    padding: 0 0 2em 0 !important;
}
.other_clients .buy-button:hover {
    background-color: #da92a6;
    color: #fff;
}
.taxons.other_clients .ui.card > .content {
    border: none !important;
}
.other_clients_wrapper{
    margin-top:5em;
}
.other_clients_header {
    border-bottom: 2px solid #da92a6;
    color: black;
    font-family: roboto-bold;
    font-size: 2em;
    line-height: 2em;
}
.other_clients_wrapper {
    margin-right: 5em;
}
.taxons.other_clients .photo_and_price .ui.image {
    max-height: 200px;
    object-fit: contain;
}
.other_clients .sylius-product-original-price, .other_clients .sylius-product-price {

    font-size: 20px;
}
.other_clients .photo_and_price{
    background-color: white;
}
.other_clients .to_style_height{
    padding: 2em !important;
}

#product-price.ui.header,
#sylius-product-name.ui.header{
    display: block;
}
.ui.top.attached.large.tabular.menu,
.ui.bottom.attached.tab.segment.active {
    background-color: #f5f5f5;
    border: none;
    position: relative;
}

.ui.bottom.attached.tab.segment.active {
    padding: 2em;
    border: none;
}
.tabular.ui.menu::after {
    content: unset;
}
.ui.attached.tabular.menu {
    justify-content: space-between;
}
.ui.top.attached.large.tabular.menu::before {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-bottom: 1px solid black;
    width: calc(100% - 4em);
    margin: 0 2em;
}

#sylius-product-attributes,
#sylius-product-attributes td,
#sylius-product-attributes tr,
#sylius-product-attributes tbody {
    border: none !important;
    background-color: transparent !important;
}

#retailers-shops, #retailers-shops-map {
    max-height: 500px;
    overflow: auto;
}

#retailers-shops > .two.column.row {
    margin-bottom: 1.5em;
}

.cross {
    position: relative;
    display: inline-block;
}

.cross:after,
.cross:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #da92a6;
}
.cross:before {
    transform: rotate(8deg);
}
.cross:after {
    transform: rotate(-8deg);
}
.prices_show {
    display: flex;
    padding: 1em 1em;
}

#product-original-price {
    float: right;
    text-align: right;
    font-family: 'Roboto';
    font-size: 3em;
    color: #000 !important;
}
.crossed_price{
    width: 40%;
}

.ui.pagination.menu div:first-child,
.ui.pagination.menu a:last-of-type{
    display: none;
}

.ui.pagination.menu .item {
    background-color: #a6a8a7;
    color: #fff;
    margin: 0 0.5em;
}
.ui.pagination.menu .item:hover{
    background-color: #da92a6;
    color: #fff;
}
.ui.pagination.menu .item.active {
    background-color: #da92a6;
    color: #fff;
}
.ui.pagination.menu .item {
    min-width: unset;
}

.page-category {
    padding: 0.6em 1em;
    border: 1px solid #da92a6;
    border-radius: 20px;
    font-size: 1em;
    margin: 0.5em 0.5em;
    color: #fff;
    background-color: #da92a6;
    transition: 0.2s;
}
.page-category:hover{
    color: #da92a6;
    background-color: #fff;
    transition: 0.2s;
}
.page-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.download_title.first_line,
.download_title.second_line,
.shops_title.first_line,
.shops_title.second_line,
.shops_title.third_line{
    font-family: Roboto-bold;
    text-transform: uppercase;
    margin: 0;
}
.shops_title.first_line,
.download_title.first_line{
    color: #da92a6;
}
.shop-container{
    height: auto;
}
.shop-list-container{
    margin-bottom: 3em !important;
}
.download_title.first_line:after,
.download_title.first_line:before,
.download_title.second_line:after,
.download_title.second_line:before,
.shops_title:after,
.shops_title:before{
    content: unset;
}
.ui.fluid.card.download{
    position: relative;
}
.shops{

}
.shops_title,
.download_title{
    font-size: 2.5rem;
}
.download_titles{
    padding: 3rem 3rem 1rem 3rem ;
    z-index: 1;
    position: relative;
}
.ui.fluid.card.download {
    margin-top: -2rem;
    padding: 3rem 3rem 3rem 3rem;
}
.download_image {
    padding: 2rem;
    object-fit: contain;
    height: 100%;
}
.file_name {
    margin-left: 2rem;
}
.download_link{
    margin-left: 2rem;
    margin-top: 2rem;
    position: absolute;
    bottom: 2rem;
}
.download_button {
    padding: 0.3rem 1.8rem;
    background-color: #da92a6;
    color: #fff;
    font-family: roboto-bold;
    font-size: 1.3em;
    border: 1px solid #da92a6;
    transition: 0.2s all;
}
.download_button:hover{
    background-color: #fff;
    color: #da92a6;
}

.ui.fluid.card.download {
    height: 350px;
}
.img_down {
    height: 70%;
}
#voivodeship {
    background-color: transparent;
    color: #da92a6;
    width: 60%;
    border: 1px solid #da92a6;
    padding: 1rem 1rem;
    font-family: roboto-bold;
    font-size: 1.3rem;
}

.slick-slide .slick-div{
    height: 100%;
}
.carousel-item .ui.card.to_style_height{
    height: 100%;
}
.summary {
    display: flex;
    justify-content: space-between;
    margin: 0 1em;
}
.continue_empty {
    display: flex;
}
.summary .ui.button {
    padding: 0.5em 2.5em!important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem!important;
    font-family: RobotoCondensed-Bold;
    height: 100%;
    border-radius: 0;

}

.bg_white {
    background: #fff !important;
}

h4 .item {
    color: #fff;
}

h2.ui.inverted.header, h2.ui.inverted.header > a {
    font-size: 1.07142857rem;
    color: #fff !important;
}

.promotion-icon {
    position: absolute;
    font-size: 32px;
    z-index: 100;
    background: #da92a6;
    padding: 9px;
    color: #fff;
    font-family: 'Roboto-Bold';
}

.latest-icon {
    position: absolute;
    right: 0;
    font-size: 32px;
    z-index: 100;
    background: #da92a6;
    padding: 9px;
    color: #fff;
    font-family: 'Roboto-Bold';
}

.ui.large.loadable.form {
    font-size: 12px;
    font-family: Roboto;
}


label.form-label {
    font-size: 11px;
    font-family: 'RobotoCondensed-Light';
    text-transform: uppercase;
}
select.form-select{
    font-family: 'RobotoCondensed-Light';
    font-size: 15px;
    text-transform: lowercase;
    color: #da92a6 !important;
}

input[type=file] {
    font-family: RobotoCondensed-Light !important;
    font-size: 13px !important;
}

option.optgroup.search__select-item {
    font-size: 17px;
    font-weight: 800;
    font-family: 'Roboto-Bold';
}

option.optelement.search__select-item {
    padding-left: 10px;
}

.search__product-wrapper{
    z-index: 3 !important;
}

#cities-filter {
    /*padding: 24px;*/
}

.city-link-filter {
    font-size: 16px;
    font-weight: 600;
    color: #da92a6;
    text-transform: capitalize;
}


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #da92a6;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/*Dropdown*/
.dropdown button.dropdown-button {
    background-color: transparent;
    color: #da92a6;
    width: 40%;
    border: 1px solid #da92a6;
    padding: 1rem 1rem;
    font-family: roboto-bold !important;
    font-size: 1.3rem !important;
    text-transform: none !important;
    text-align: left;
    margin-top: 1rem;
    transition: 0.2s;
}
.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 1rem;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-list {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #da92a6;
    min-width: 40%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    max-height: 300px;
    overflow: auto;
}
/* Links inside the dropdown */
.dropdown-list a {
    color: #da92a6;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: Roboto;
}
/* Change color of dropdown links on hover */
.dropdown-list a:hover {
    background-color: #da92a6;
    color: #fff;
}
/* Show the dropdown list on hover */
.dropdown.active .dropdown-list {
    display: block;
}
/* Change the background color of the dropdown button when the dropdown list is shown */
.dropdown:hover .dropdown-button {
    background-color: #da92a6;
    color: #fff;
}
/*Dropdown end*/


#h3_dystrybutor,
#total{
    text-align: right;
}



.sylius-quantity.ui.form {
    position: relative;
}

.sylius-quantity.ui.form input[type=number]::-webkit-inner-spin-button,
.sylius-quantity.ui.form input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}


.sylius-quantity.ui.form input[type=number]
{
    -moz-appearance: textfield;
}

.sylius-quantity.ui.form input {
    width: 80px;
    height: 42px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    border: 1px solid #eee;
}

.sylius-quantity.ui.form input:focus {
    outline: 0;
}

.quantity-nav {
    float: left;
    position: relative;
    height: 42px;
}

.quantity-button {
    position: relative;
    cursor: pointer;
    border-left: 1px solid #eee;
    width: 20px;
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-family: "Trebuchet MS", Helvetica, sans-serif !important;
    line-height: 1.7;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    background-color: #BABCBE;
    font-weight: bold;

}

.quantity-button.quantity-up {
    position: absolute;
    height: 50%;
    top: 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-button.quantity-down {
    position: absolute;
    bottom: -1px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#sylius-cart-items .sylius-product-name{
    font-size: 1.5rem;
    margin-bottom: 0.4em
}


#sylius-cart-items .ui.list.sylius-product-options {
    font-size: 0.6em;
    margin-top: 0em
}

#sylius-cart-items .ui.list.sylius-product-options .item {
    padding: 0;
    font-family: Roboto-light;
}

#sylius-cart-items .sylius-unit-price,
#sylius-cart-items .sylius-quantity.ui.form input{
    font-size: 1.5rem;
}
#sylius-cart-items .sylius-total {
    font-size: 2rem;
}


.ui.circular.button > .icon {
    font-size: 0.7em;
}

#h3_dystrybutor h3{
    font-family: Roboto-Bold;
}



.slider-top .ui.fluid.image {
    height: calc(100vw * 0.312);
}

.items_in_basket{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.price_in_basket {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.checkout_adress .ui.large.icon.labeled.button {*/
/*    height: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/
.checkout_shipping form .item {
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
}
.checkout_payment .four.wide.column.payment-method {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#sylius-payment-methods .ui.grid {
    justify-content: center;
}


.header.sylius-product-name {
    font-weight: 700;
    font-size: 1.28571429rem;
    margin-top: -.21425rem;
    line-height: 1.28571429rem;
}
.box-description .long_desc{
    display: none;
}
.box-description a{
    color: #da92a6;
}
.box-description a:hover{
    color: #da92a6;
    text-decoration: underline;
}
.box-description.full .long_desc{
    display: block;
}
.taxonomy_description {
    padding-top: 1em;
    border-top: 1px solid #da92a6;
}
.taxon_image{
    text-align: center;
}
#footer{
}
img.wp-smiley, img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 .07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}
.lozka_kontynentalne .spacer {
    height: 100px;
}
.lozka_kontynentalne .spacer2 {
    height: 100px;
}
.lozka_kontynentalne a{
    color: #fff;
}
.lozka_kontynentalne a:hover{
    color: #da92a6;
}
.check_lozka {
    width: 130px;
    display: block;
    padding: 0.3em 0em !important;
    text-align: center;
    margin-top: 4rem;
    color: #fff;
    background-color: #da92a6;
    border: 1px solid #da92a6;
    font-size: 1.2em;
    font-weight: bold;
    font-family: roboto;
    transition: 0.1s;
}
.check_lozka:hover {
    color: #da92a6!important;
    background-color: #fff;
}
.lozka_grid img{
    margin-bottom: 2rem !important;
}
.lozka_grid{
    justify-content: space-between;
    margin-top: 4rem !important;
    font-size: 1.3em;
}
.lozka_kontynentalne strong{
    font-family: Roboto-Bold;
}

/*.ui.dimmer{
    position: fixed;
    bottom: 0em !important;
    top: unset !important;
}*/

.retailer-content .ui.secondary.button{
    background-color: #da92a6;
    margin-top: 20px
}

.scrolling.dimmable.dimmed > .dimmer
{
    overflow: unset;
}

#trustedshopsBadge{
    z-index:1000;
}

.ui.dimmer.modals.page.transition.visible.active:has(>#modal-cookies){
    height: 150px;
}

#modal-cookies{
    width: 100%;
    text-align: left;
}

body.animating.in.dimmable, body.dimmed.dimmable{
    overflow: unset;
}

.scrolling.dimmable.dimmed{
    overflow: hidden;
}

.ui.message{
    margin: 0px;
    padding: 3px 10px;
}

.ui.icon.message > .icon:not(.close){
    font-size: 2em;
}



/*MOBILKI*/
@media only screen and (min-width: 1500px) {
    .ui.container {
        width: 1452px;
    }
}
@media only screen and (min-width:1000px) and (max-width:1700px) {
    .carousel-latest-products-left.ui.small.black.icon.button.slick-arrow, .carousel-promotion-products-left.ui.small.black.icon.button.slick-arrow {
        left: 2.5%;
        top: 105%;
        padding: 0.3em;
    }
    .carousel-latest-products-right.ui.small.black.icon.button.slick-arrow, .carousel-promotion-products-right.ui.small.black.icon.button.slick-arrow {
        right: 2.5%;
        top: 105%;
        padding: 0.3em;
    }

}
@media only screen and (max-width: 1500px) {
    .ui.container {
        width: auto;
        margin-left: 2em !important;
        margin-right: 2em !important;
    }
    .carousel-latest-products .slick-slide,
    .carousel-promotion .slick-slide {
        width: calc((100vw - 4em) / 3);
    }
}
@media only screen and (max-width: 1465px) {

    .ui.container.checkout_adress {
        padding: 0 2em !important;
    }
    .column.register-choice-box:nth-child(3) {
        margin-top: 2em;
    }
}
@media only screen and (max-width: 1370px) {
    .ui.grid {
        margin-top: 0rem;
        margin-bottom: 0rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    #main-menu {
        padding-left: 1em;
        padding-right: 1em;
    }
    .three-squares {
        left: 88%;
    }
    .lds-ripple {
        margin-left: 50%;
        margin-right: 50%;
        transform: translateX(-50%);
    }
}
@media only screen and (min-width:1200px) and (max-width:1370px) {
    .search__selects-section {
        justify-content: center;
    }

    .carousel-latest-products .slick-slide,
    .carousel-promotion .slick-slide {
        width: calc((100vw / 3) - 1em);
        padding: 26px;
    }

    .carousel-latest-products .slick-slide:first-child,
    .carousel-promotion .slick-slide:first-child {
        /*margin-left: 26px;*/
    }
}
@media only screen and (max-width:1250px) {
    .fullscreen .ui.grid.container {
        width: auto !important;
    }
}
@media only screen and (max-width: 1249px) {
    .carousel-item .slider-text {
        max-width: 100%;
        padding: 1em 2em;
        top: 0;
        left: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search__selects-section {
        padding: 1em;
    }
    .carousel-latest-products .slick-slide, .carousel-promotion .slick-slide {
        width: calc(100vw / 3);
        padding: 26px;
    }
    .slick-track {
        justify-content: center;
    }
    .new_products .slick-dots,
    .promotions_products .slick-dots{
        top: 100%;
    }
    .carousel-latest-products-left.ui.small.black.icon.button.slick-arrow,
    .carousel-promotion-products-left.ui.small.black.icon.button.slick-arrow{
        left: 0;
        top: 105%;
        padding: 0.3em;
    }
    .carousel-latest-products-right.ui.small.black.icon.button.slick-arrow,
    .carousel-promotion-products-right.ui.small.black.icon.button.slick-arrow{
        right: 0;
        top: 105%;
        padding: 0.3em;
    }
    .ui.grid > [class*="eight wide"].column.mw_0{
        width: 0!important;
        display: none;
    }
    .ui.grid > [class*="eight wide"].column.mw_100{
        width: 100%!important;
    }
    .pic_text{
        padding: 2em 1em!important;
        font-size: 90%;
        background-color: rgba(255,255,255,0.3);
    }
    .fullscreen .ui.grid.container{
        margin: 0!important;
    }

}
@media only screen and (max-width:1200px) {
    .sylius-product-original-price, .sylius-product-price{
        float: unset;
    }

    .configurator-question-header {
        flex-wrap: wrap;
        padding: 16px;
    }

    .configurator-question-body {
        width: 100%;
    }
}


@media only screen and (max-width: 1199px) {
    .three-squares {
        left: 98%;
        width: 17px;
        height: 18px;
    }

    .none_1200 {
        display: none;
    }
    #sylius-cart-items img {
        margin-right: 1rem;
    }
    .register-choice-box:first-child{
        margin-bottom: 2em;
    }
    .register-choice-box {
        margin-right: 0px !important;
    }
}
@media only screen and (min-width: 1025px) {
    .desktop-none{
        display: none!important;
    }
}
@media only screen and (max-width: 1024px) {
    .search__selects-section {
        display: block; }
    .search__selects-section > div {
        margin-bottom: 20px; }
    .search__button {
        margin: 0 auto; }
    .ui.grid.shop-container{
        margin-top: 1rem;
        height: 300px;
    }
    .product_body .column.w-60{
        width: 100% !important;
    }
    .product_body .column.w-40{
        width: 100% !important;
    }
    .mobile-none{
        display: none!important;
    }
    .icons_product{
        justify-content: space-around;
    }
    .other_clients_wrapper.desktop-none {
        margin-right: 0em;
    }
    .ui.three.doubling.cards.taxons.other_clients {
        flex-wrap: nowrap;
    }
    #sylius-product-adding-to-cart .fields {
        display: inline-flex !important;
        padding: 15px;
        width: 100%;
        justify-content: start;
    }
    #sylius-product-adding-to-cart .ui.form .four.fields > .field,
    #sylius-product-adding-to-cart .ui.form .four.fields > .fields {
        width: auto;
    }
    .ui.basic.segment .ui.three.column.stackable.grid {
        justify-content: center;
    }

    #sylius-product-adding-to-cart.ui.form .field {
        width: auto !important;
    }

    #refresh_cart {
        margin-top: 0em;
    }
}

@media only screen and (max-width: 1023px) {
    .p-0 li.item {
        border-bottom: 1px solid #cecece;
    }

    .hotele {
        text-align: justify;
        width: 100%;
    }

    .hotele img {
        max-width: 225px;
    }

    #main-menu ul {
        display: block;
    }

    .menu_button {
        display: block;
        cursor: pointer;
    }
    #main-menu ul{
        display: none;
    }
    #main-menu .searchbox-form{
        display: flex;
        justify-content: center;
    }
    .ui.one.column.grid .searchbox-form{
        display: none;
    }
    #log_reg_bas {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(90vw - 2em) !important;
        padding: 0 !important;
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
    #log_reg_bas .ui.right.stackable.menu {
        flex-direction: row;
    }
    #log_reg_bas .ui.stackable.menu .item {
        width: auto !important;
    }
    .mobile_none{
        display: none;
    }
    .pusher .ui.container header .logo{
        display: flex;
        justify-content: center;
    }
    .pusher .ui.container header .logo a{
        margin-top: 3em !important;
    }
    #log_reg_bas .column {
        padding: .5em 0 !important;
    }
    #log_reg_bas .item {
        padding: 0 0.5em !important;
    }
    #sylius-cart-button{
        display: flex;
        justify-content: end;
    }
    #main-menu ul .item{
        font-size: 1em;
        padding: 0.5em;
        justify-content: center;

    }
    #show_menu {
        padding: 0.8em;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    #main-menu {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 0;
        align-items: start;
    }
    .ui.stackable.grid > .column:not(.row){
        padding: 0 !important;
    }
    .ui.basic.segment{
        margin-bottom: 0;
    }
    .pusher .ui.hidden.divider {
        height: 1em;
        font-size: 0.6em;
        margin: 0;
    }
    .searchbox-form {
        max-width: 100%;
    }
    .ui.input.searchbox{
        max-width: 100%;
        padding: 0;
    }
    .ui.icon.input > input {
        padding: 0.2em 0.4em;
        font-size: 0.9em;
    }
    .menu_button{
        display: flex;
        align-items: center;
    }
    #log_reg_bas .ui.two.column.grid{
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .carousel-wrapper.slider-fullscreen.mb-5 {
        height: calc(100vw * 0.312);
    }
    .slider-top .ui.fluid.image {
        /*height: 300px;*/
    }
    .carousel-left-top.ui.small.black.icon.button.slick-arrow {
        padding: 0.3em;
        left: 0.3em;
        top: calc(100vw * 0.352);
    }
    .carousel-right-top.ui.small.black.icon.button.slick-arrow {
        padding: 0.3em;
        right: 0.3em;
        top: calc(100vw * 0.352);
    }
    .carousel-item .slider-text {
        max-width: 100%;
        padding: 1em 2em;
        top: 0%;
        left: 0%;
    }
    .slick-dots ul{
        display: flex;
    }
    .slick-dots {
        display: flex;
        position: absolute;
        top: calc(100vw * 0.322);
        bottom: unset;
        width: 100%;
        right: 0;
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;
    }
    .search__select {
        width: 100%;
        height: 40px;
    }

    .filter_wrap{
        margin-right: 0;
    }

    .search__select-chevron-icon{
        padding: 1.2em;
    }
    .taxons .filter_menu {
        flex-direction: column;
        align-items: start;
        margin-top: 1em;
        padding-left: 40px;
        margin-bottom: 0.5em;
    }
    .taxons .inside_filter {
        flex-direction: column;
        align-items: start;
        display: none;
    }
    .taxons .filter_menu .ui.header:last-child, .taxons .filter_menu .ui.header {
        margin-bottom: 2em;
        margin-top: 2em;
    }
    .taxons .filter_button{
        display: block;
    }
    .filter_button{
        border: 1px solid black;
        padding: 0.5em 0.5em;
        border-radius: 5px;
        cursor: pointer;
    }
    .ui.stackable.grid.taxon_margin_top {
        margin-top: 0;
    }
    .inside_filter .ui.right.floated.small.header {
        float: left;
    }
    .ui.three.cards > .card {
        width: 100%;
    }
    .ui.pagination.menu {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 2em;
        padding-top: 1em;
    }
    .blog .item.bitbag-page {
        margin: 3em;
    }
    .item.bitbag-page, .item.bitbag-page .column {
        height: auto;
    }
    .item.bitbag-page:nth-child(odd) .image_blog::after,
    .item.bitbag-page:nth-child(even) .image_blog::after,
    .item.bitbag-page:nth-child(odd) .blog_content::after,
    .item.bitbag-page:nth-child(even) .blog_content::after{
        content: unset
    }
    .item.bitbag-page .content .column.blog_content{
        padding: 2em !important;
    }
    .item.bitbag-page:nth-child(even) .content .column.blog_content{

        background-color: #f5f5f5;
    }
    .blog_top_image {
        padding-left: 0;
        object-fit: cover;
        width: 100%;
    }
    .ui.one.column.blog_top {
        flex-direction: column;
    }
    .blog_top_content {
        padding: 2em;
    }

}
@media only screen and (min-width: 1023px) and (max-width: 1370px) {
    .search__selects-section div[class*="search_"]{
        z-index: 2;
    }
    .search__selects-section {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1em 3em;
    }
    .search__button{
        margin-top: 1em!important;
    }

}
@media only screen and (min-width: 767px) and (max-width: 1024px) {
    .ui.stackable.grid > .column:not(.row).logo{
        width: 100%;
    }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
    #log_reg_bas {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
@media only screen and (max-width:991px) {
    #retailers-shops h4{
        font-size: 1.5rem;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    #retailers-shops .two.column.row.ui.grid{
        padding: 0px;
    }

    #retailers-shops .column{
        padding: 0px;
    }

    #retailers-shops > .two.column.row{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #666666;
    }

    #retailers-shops .working-hours{
        display: none;
    }

    #retailers-shops .column{
        padding: 0px
    }

    .add-to-basket-retailer-column .ui.labeled.icon.button, .add-to-basket-retailer-column .ui.labeled.icon.buttons .button{
        font-size: 1.2em !important;
    }

    #retailers-shops button{
        font-size: 1.1rem !important;
    }

    #main-menu{
        margin-bottom: 25px;
        border-bottom: 1px solid #cecece;
        padding-bottom: 10px;
    }
    .numberCircle{
        width: 45px;
    }
    #menu{

    }
    #menu .ui.stackable.menu .item{
        width: auto !important;
        font-size: 12px;
        padding: 6px;
    }
    #menu .ui.right.stackable.menu
    {
        flex-direction: row;
    }

    .carousel-latest-products .slick-slide,
    .carousel-promotion .slick-slide {
        width: 723px;
        padding: 26px;
    }
    .ui.container.summary_site {
        width: auto;
    }
    .ui.circular.button > .icon {
        font-size: 0.7em;
        width: 2em;
        height: 2.3em;
    }
    .ui.circular.icon.button.sylius-cart-remove-button {
        padding: 0 !important;
    }
    .carousel-left-top.ui.small.black.icon.button.slick-arrow {
        display: none !important;
    }
    .carousel-right-top.ui.small.black.icon.button.slick-arrow {
        display: none !important;
    }
    .single.line {
        flex-basis: 100%;
        justify-content: center;
        display: flex;
    }
    .price_in_basket .right.aligned {
        padding-right: 1rem;
    }

    .quantity-button.quantity-up {
        height: 100%;
        top: 0;
        bottom: 0;
        right: -50px;
        width: 40px;
    }
    .quantity-button.quantity-down {
        height: 100%;
        top: 0px;
        bottom: 0;
        right: -95px;
        width: 40px;
        border-bottom: 1px solid #fff;
    }
    .summary .ui.button {
        padding: 0.5em 1em !important;
    }
    .three-squares{
        display: none;
    }
    .right.floated.four.wide.column.fw_mobile {
        width: 100% !important;
    }
}
@media only screen and (min-width:768px) and (max-width:1200px) {
    .ui.three.cards > .card {
        width: calc(50% - 2em);
    }
    #products.ui.three.cards{
        justify-content: center;
    }

}
@media only screen and (max-width:768px) {
    .ui.five.column.center.aligned.grid .three-squares{
        display: none;
    }
    .ui.five.column.center.aligned.grid img{
        scale: 75%;
    }
    .ui.five.column.center.aligned.grid .column {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ui.two.column.bottom.aligned.grid.infos .nine.wide.column {
        display: none;

    }
    .ui.two.column.bottom.aligned.grid.infos .seven.wide.column {
        width:100%!important;
    }
    #svg{
        width: 100vw;
        margin-left: 15vw;
    }
    .ui.grid.shop-container {
        height: 700px;
    }

    #voivodeship,
    #cities-filter{
        margin-left: 20%;
    }
    .ui.grid.shop-container {
        height: 700px;
    }
    .dropdown button.dropdown-button {
        width: 60%;
    }
}
@media only screen and (min-width:768px) {
    #footer {
        padding: 4em;
        text-align: center;
    }

    .ui.three.doubling.cards.taxons.other_clients {
        flex-wrap: wrap;
        justify-content: center;
    }
    .taxons .photo_and_price .ui.image {
        height: 305px;
        object-fit: cover;
        width: 100%;
    }
    .other_clients .buy-button {
        width: auto;
    }
}
@media only screen and (max-width:767px) {
    .carousel-latest-products .slick-slide,
    .carousel-promotion .slick-slide {
        width: calc(100vw - 2em);
        padding: 26px;
    }
    .summary {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
    }
    .continue_empty {
        display: flex;
        justify-content: center;
        margin-top: 2em;
    }
    #h3_dystrybutor, #total {
        text-align: center;
    }
    .infos #h3_dystrybutor{
        margin-bottom: 1em !important;
    }
    .infos .column {
        display: flex!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #log_reg_bas {
        width: calc(95vw - 2em) !important;
    }
    .ui.three.doubling.cards.taxons.other_clients {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media only screen and (max-width:520px) {
    #sylius-cart-items img {
        width: 150px;
    }
    .price_in_basket {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 50%;
        margin-right: 50%;
        transform: translateX(-30%);
    }
    #sylius-cart-items .center.aligned {
        margin-right: 56px;
    }
    .ui.five.column.center.aligned.grid .column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 33%;
        padding: 0;
        margin-bottom: -1.8em;
    }
    .single.line .ui.header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1em;
    }
    .continue_empty {
        flex-wrap: wrap;
    }
    .summary #checkout-button,
    .summary #sylius-cart-update,
    .summary #sylius-cart-clear,
    .summary form{
        width: 100%;
        flex-basis: 100%;
    }

    #sylius-cart-update{
        margin-right: 0;
        margin-bottom: 1.5em;
    }
    .continue_empty {
        margin-right: 3px;
        margin-left: -3px;
    }
}
@media only screen and (max-width:453px) {
    .ui.attached.tabular.menu {
        justify-content: space-between;
        flex-direction: column;
    }
    .ui.tabular.menu .item {
        width: 100%;
    }
    .ui.labeled.icon.button {
        margin-bottom: 0.5em;
    }
    .add-to-basket-retailer-column .ui.labeled.icon.button, .add-to-basket-retailer-column .ui.labeled.icon.buttons .button {
        height: auto;
    }
    #retailers-shops .column {
        padding: 0px;
    }
    #log_reg_bas .ui.right.stackable.menu {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width:450px) {
    #svg {
        width: 130vw;
        margin-left: 5vw;
        left: 40px;
    }
}
@media only screen and (max-width: 403px) {
    .slider-top-button.mt-5 {
        margin-top: 1em !important;
        padding: 1.2em 3em;
    }
    .slider-text h3{
        font-size: 2em;
    }
    /*.ui.container {*/
    /*    width: auto !important;*/
    /*    margin-left: 0em !important;*/
    /*    margin-right: 0em !important;*/
    /*}*/
    .slider-fullscreen{
        /*margin: 0 0 4em 0 !important*/
    }
    #voivodeship {
        width: 70%;
    }
}
