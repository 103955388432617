@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto-Black";
    src: url("../fonts/roboto/Roboto-Black.ttf");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("../fonts/roboto/Roboto-Bold.ttf");
}

@font-face {
    font-family: "Roboto-Italic";
    src: url("../fonts/roboto/Roboto-Italic.ttf");
}

@font-face {
    font-family: "Roboto-Light";
    src: url("../fonts/roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto-LightItalic";
    src: url("../fonts/roboto/Roboto-LightItalic.ttf");
}

@font-face {
    font-family: "Roboto-Medium";
    src: url("../fonts/roboto/Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto-Thin";
    src: url("../fonts/roboto/Roboto-Thin.ttf");
}

@font-face {
    font-family: "Roboto-ThinItalic";
    src: url("../fonts/roboto/Roboto-ThinItalic.ttf");
}

@font-face {
    font-family: "RobotoCondensed-Regular";
    src: url("../fonts/roboto_condensed/RobotoCondensed-Regular.ttf");
}

@font-face {
    font-family: "RobotoCondensed-Light";
    src: url("../fonts/roboto_condensed/RobotoCondensed-Light.ttf");
}

@font-face {
    font-family: "RobotoCondensed-Bold";
    src: url("../fonts/roboto_condensed/RobotoCondensed-Bold.ttf");
}
